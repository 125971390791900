import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91service_93YKYsfs5QwOMeta } from "/opt/buildhome/repo/pages/services/[service].vue?macro=true";
import { default as indexVrpVjnZSa3Meta } from "/opt/buildhome/repo/pages/services/index.vue?macro=true";
import { default as testimonialsywb5TQR190Meta } from "/opt/buildhome/repo/pages/testimonials.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "services-service",
    path: "/services/:service()",
    component: () => import("/opt/buildhome/repo/pages/services/[service].vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/opt/buildhome/repo/pages/services/index.vue")
  },
  {
    name: "testimonials",
    path: "/testimonials",
    component: () => import("/opt/buildhome/repo/pages/testimonials.vue")
  }
]